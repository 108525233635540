<template>
  <div class="row h-100">
    <div class="col chat-rooms col-3 bg-white border-right">
      <div class="chat-room-header p-3 border-bottom bg-shadow row d-none d-lg-block d-md-block">
        <input v-if="!formControl.searching" type="text" class="border-rounded px-4 p-2 w-100 form-control border-0 bg-light" placeholder="Search people..." v-model="formControl.search" v-on:click="toggleSearch(true)"/>
        <div v-else class="btn-group">
          <div class="btn my-2" v-on:click="toggleSearch(false)"><i class="fas fa-chevron-left"/></div>
          <input type="text" ref="user-search" class="border-rounded px-4 p-2 w-100 form-control border-0 bg-light" placeholder="Search people..." v-model="formControl.search"/>
        </div>
      </div>
      <div class="chat-room-body row">
        <div v-if="formControl.searching">
          <div v-if="chatUsers.length === 0" class="text-muted p-5 text-center">
            <small>We couldn't find who you were looking for. Please try again.</small>
          </div>
          <div v-for="chatUser in chatUsers" v-bind:key="chatUser._id" v-on:click="openChat(chatUser)" class="chat-row row">
            <div class="col-auto px-4 py-2">
              <img :src="$getAvatar(chatUser.avatar)" style="width: 60px; border-radius: 100%;" class="p-2 border"/>
            </div>
            <div class="col chat-row-details px-0 py-2">
              <div class="header">{{chatUser.name}}</div>
              <div class="sub-header">{{chatUser.email}}</div>
            </div>
          </div>
        </div>
        <div v-else>
          <div v-if="rooms.length === 0">
            <div class="py-5 my-5 text-center text-muted">
              <div class="py-5 my-5">
                <img src="@/assets/images/iripple-helpdesk-icon-dark.png" class="mb-2" style="opacity: 0.15; filter: saturate(0.5); width: 150px"><br>
                No conversations yet
              </div>
            </div>
          </div>
          <div v-else>
            <div v-for="room in rooms" v-bind:key="room.code" v-on:click="openChat(null, room)" v-bind:class="[room.code === activeRoom.code ? `active` : ``, !room.seenAt ? `` : `unseen`]" class="chat-row row">
              <div class="col chat-row-avatar">
                <img :src="$getAvatar(room.avatar)"/>
                <div v-if="$isOnline(room.users.find(r => r._id !== user._id), onlineClients)" class="chat-online-indicator chat-row-online-indicator" />
              </div>
              <div class="col chat-row-details d-none d-lg-block d-md-block">
                <div class="header">{{room.name}}</div>
                <div class="sub-header">
                  {{room.last_chat ? room.last_chat.message : 'New message'}}
                </div>
                <span class="timestamp">{{room.last_chat ? formatDate(room.last_chat.createdAt) : ''}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col bg-light chat-threads">
      <div v-if="activeRoom.code">
        <div class="chat-room-header p-3 bg-shadow border-bottom bg-white row">
          <div>{{activeRoom.name}}</div>
          <small class="text-muted text-left">{{activeRoom.sub_name}}</small>
        </div>
        <div class="chat-room-body row bg-light" v-on:click="focusChat">
          <ChatThreadComponent :room="activeRoom" :code="activeRoom.code"/>
        </div>
        <div class="chat-room-footer p-2 border-bottom bg-shadow row">
          <input type="text" class="px-4 p-2 w-100 form-control border-0 bg-light" ref="chat-form" placeholder="Write something..." v-model="formControl.text" v-on:keyup.enter="sendChat"/>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import Vue from 'vue'
  import moment from 'moment'
  import ChatThreadComponent from './ChatThread';
  import SocketioService from '../../services/socketio.service.js';
  import successSfx from '../../assets/audio/notifications/notification-2.mp3'

  let audio
  export default {
    props: {
      enableChats: Boolean
    },
    components:{
      ChatThreadComponent
    },
    data() {
      return {
        user: this.$userData.user,
        socket: null,
        rooms: [],
        users: [],
        onlineClients: [],
        formControl: {
          search: '',
          searching: false,
          text: '',
          attachments: []
        },
        activeRoom: {}
      }
    },
    methods: {
      loader: function(val){
        this.$emit('loader', val)
      },
      toggleSearch: async function(val){
        this.formControl.searching = val
        if(!val) this.formControl.search = ''
        await this.$sleep(0)
        this.$refs['user-search'] && this.$refs['user-search'].focus()
      },
      sendChat: function(){
        if(this.activeRoom.isNew)
          this.socket.emit('subscribe room', this.activeRoom.code)
        if(this.formControl.text.trim() !== '')
          this.socket.emit('send chat', {
            room: this.activeRoom,
            chat: {
              room: this.activeRoom,
              sender: this.user,
              message: this.formControl.text,
              attachments: this.formControl.attachments,
              seenAt: null
            }
          })
        this.formControl.text = ''
      },
      openChat: async function(chatUser, room){
        if(room && this.activeRoom.code !== room.code){
          this.activeRoom = room
          this.focusChat()
          // if(room.chat.chats.length > 0) this.seenChat(room.chats.reverse()[0])
          return
        }
        else if(room && this.activeRoom.code === room.code){
          this.focusChat()
          // if(room.chat.chats.length > 0) this.seenChat(room.chats.reverse()[0])
          return
        }
        else{
          this.rooms = this.rooms.filter(r => !r.isNew)
          this.toggleSearch(false)
          let code = [this.user._id, chatUser._id].sort().join('-')
          let exists = this.rooms.find(r => r.code === code)
          let new_room = exists || {
            code: code,
            name: chatUser.name,
            sub_name: chatUser.email,
            avatar: chatUser.avatar,
            users: [this.user._id, chatUser._id],
            last_chat: null,
            seenAt: true,
            isNew: true,
            chats: [],
            page: 1
          }
          if(!exists)
            this.rooms.push(new_room)
          this.activeRoom = new_room
          await this.$sleep(500);
          this.focusChat()
        }
      },
      focusChat(){
        this.$refs['chat-form'] && this.$refs['chat-form'].focus()
        Vue.prototype.$hideChatNotifications = this.activeRoom.code
      },
      validateUser: async function(){
        if(!this.enableChats){
          this.$alertify({
            group: 'notification',
            title: `Access denied`,
            type: 'warning',
            text: `You don't have access for this module.`
          })
          await this.$sleep(500);
          this.$router.push('/')
          return 0
        }
        await this.getRooms()
        await this.getUsers()
      },
      async getRooms(){
        this.loader(true)
        try {
          const response = await this.$http.get(
            `${this.$apiEndpoint}/v1/rooms/`,
            {
              headers: {
                'Authorization': this.$userData.authToken,
                'Access-Control-Allow-Origin' : '*',
              }
            }
          );
          this.rooms = response.data.rooms.map(r => Object.assign(r.room, { chats: r.chats })) || []
          this.rooms.forEach(room => {
            this.socket.emit('subscribe room', room.code)
          })
          this.loader(false)
        } catch (error) {
          this.loader(false)
          console.log(error)
        }
      },
      async getUsers(){
        this.loader(true)
        try {
          const response = await this.$http.get(
            `${this.$apiEndpoint}/v1/users?role=Manager,Agent,Customer`,
            {
              headers: {
                'Authorization': this.$userData.authToken,
                'Access-Control-Allow-Origin' : '*',
              }
            }
          );
          this.users = response.data.users.filter(r => r._id != this.user._id)
          this.loader(false)
        } catch (error) {
          console.log(error)
          this.loader(false)
        }
      },
      formatDate: function(date){
        return moment(date, 'YYYY/MM/DD HH:mm:ss').add({hours: 8}).fromNow()
      }
    },
    computed: {
      chatUsers: function(){
        return this.users.filter(record => (
          (this.formControl.search.trim() === "") ||
          (record.name ? record.name.toLowerCase().match(new RegExp(this.formControl.search.trim().toLowerCase())) : false) ||
          (record.email ? record.email.toLowerCase().match(new RegExp(this.formControl.search.trim().toLowerCase())) : false)
        ))
      }
    },
    created() {
      if(this.$userData && !this.$socket){
        this.socket = SocketioService.setupSocketConnection()
        Vue.prototype.$socket = this.socket
      }
      else if(this.$userData && this.$socket){
        this.socket = this.$socket
      }
    },
    mounted(){
      this.validateUser()
      this.socket.on('broadcast clients', (clients) => {
        this.onlineClients = clients || []
      })
      this.socket.on('broadcast chats', (chats) => {
        chats.forEach((chat, i) => {
          let rooms = Object.assign([], this.rooms)
          let roomIndex = rooms.findIndex((r => r.code == chat.room.code))
          if(roomIndex < 0){
            rooms.push(chat.room)
            roomIndex = rooms.findIndex((r => r.code == chat.room.code))
          }
          rooms[roomIndex] = Object.assign({}, rooms[roomIndex], chat.room)
          rooms[roomIndex].chats ||= []
          rooms[roomIndex].chats.push(chat)
          if(this.activeRoom && this.activeRoom.code === rooms[roomIndex].code){
            this.activeRoom = rooms[roomIndex]
            // this.seenChat(chat)
          }
          else{
            if(chat.sender._id !== this.user._id && i === 0) {
              audio = new Audio(successSfx)
              audio.volume = 0.2
              audio.play()
            }
          }
          this.rooms = Object.assign([], rooms)
        })
      })
    },
    beforeUnmount() {
      SocketioService.disconnect()
      Vue.prototype.$socket = false
      Vue.prototype.$hideChatNotifications = false
    }
  }
</script>
