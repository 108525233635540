<template>
  <div class="p-2 px-3" ref="chat-bubble-wrapper">
    <div v-for="(chat, i) in chats" v-bind:key="chat.id" >
      <div v-if="false" class="text-center">
        <div class="text-header my-3 cursor-pointer bg-white btn px-3 border-rounded bg-shadow center">
          Load more messages
        </div>
      </div>
      <div v-if="showDate(chats, i)" class="text-center text-header">
        <small>{{showDate(chats, i)}}</small>
      </div>
      <div class="chat-bubble bg-shadow" :class="[isSender(chat) ? 'chat-bubble-sender' : 'chat-bubble-receiver', chatPosition(chats, i)]">
        {{chat.message}}
      </div>
      <br clear="all"/>
    </div>
  </div>
</template>
<script>
  import moment from 'moment'

  export default {
    props: {
      room: Object,
      code: String
    },
    data() {
      return {
        user: this.$userData.user
      }
    },
    methods:{
      isSender(chat){
        return chat && chat.sender && chat.sender._id && chat.sender._id === this.user._id
      },
      chatPosition(chats, i){
        let current = chats[i], previous = chats[i - 1], next = chats[i + 1]
        let sides = []
        if(!previous && !next)
          sides = []
        else if(!previous && next){
          if(this.isSender(next) === this.isSender(current)) sides.push('chat-bubble-border-bottom')
        }
        else if(previous && !next){
          if(this.isSender(previous) === this.isSender(current)) sides.push('chat-bubble-border-top')
        }
        else{
          if(this.isSender(previous) === this.isSender(current)) sides.push('chat-bubble-border-top')
          if(this.isSender(next) === this.isSender(current)) sides.push('chat-bubble-border-bottom')
        }
        if(this.showDate(chats, i)) sides = sides.filter(r => r !== 'chat-bubble-border-top')
        if(this.showDate(chats, (i + 1))) sides = sides.filter(r => r !== 'chat-bubble-border-bottom')
        return sides.join(' ')
      },
      showDate(chats, i){
        let current = chats[i], previous = chats[i - 1]
        if(!previous)
          return moment(current.createdAt, 'YYYY/MM/DD HH:mm:ss').add({hours: 8}).format("MMM DD, YYYY,  hh:mm A")
        else if(!current)
          return true
        else{
          current = moment(current.createdAt, 'YYYY/MM/DD HH:mm:ss')
          previous = moment(previous.createdAt, 'YYYY/MM/DD HH:mm:ss')
          return moment.duration(current.diff(previous)).asMinutes() <= 30 ? false : current.add({hours: 8}).format("MMM DD, YYYY,  hh:mm A")
        }
      },
      scrollToElement() {
        const el = this.$refs['chat-bubble-wrapper'];
        if (el) {
          el.scrollIntoView({block: "end", behavior: 'smooth'});
        }
      },
      sortChats: function(){
        return this.room.chats.sort((current, next) =>
          moment(current.createdAt, 'YYYY/MM/DD HH:mm:ss') - moment(next.createdAt, 'YYYY/MM/DD HH:mm:ss')
        )
      }
    },
    watch: {
      code: async function(){
        await this.$sleep(0)
        this.scrollToElement()
      },
      'room.chats': async function(){
        await this.$sleep(0)
        this.scrollToElement()
      }
    },
    mounted(){
      this.scrollToElement()
    },
    computed: {
      chats: function(){
        return this.sortChats()
      }
    }
  }
</script>
